

const josh1 = require("./assets/josh.jpg")
const josh2 = require("./assets/josh2.jpg")
const josh3 = require("./assets/josh3.jpg")
const josh4 = require("./assets/josh4.jpg")
const josh5 = require("./assets/josh5.jpg")
const josh6 = require("./assets/josh6.jpg")
const thoughtBubble = require ("./assets/clipart1425245.png")

export const arr = [josh1, josh2, josh3, josh4, josh5, josh6]
export const thought = thoughtBubble; 
 


